export default [
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/account/AccountSetting.vue'),
  },

  {
    path: '/reset-password/:code',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },

  {
    path: '/contact-us/:code',
    name: 'contact-us',
    component: () => import('@/views/auth/ContactUs.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },

]
