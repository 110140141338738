import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [

  ],

  /* eslint-disable global-require */
}

mock.onGet('/users-dummy/basic').reply(() => [200, data.tableBasic])
