export default [
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/views/companies/Companies.vue'),
  },

  {
    path: '/companies/company-add',
    name: 'company-add',
    component: () => import('@/views/companies/company-add/CompanyAdd.vue'),
  },

  {
    path: '/companies/company-edit/:id/',
    name: 'company-edit',
    component: () => import('@/views/companies/company-edit/CompanyEdit.vue'),
  },
]
