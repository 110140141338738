import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import orders from './routes/orders'
import claims from './routes/claims'
import account from './routes/account'
import users from './routes/users'
import companies from './routes/companies'
import companyRelationships from './routes/companyRelationships'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

var userData = JSON.parse(localStorage.getItem("userData"))

let defaultPath

//If no data, or user is Admin/Superadmin
if (userData == null || userData == undefined || userData.company.company_type_id <= 2){
  defaultPath = "orders"
}//Buyer
else if (userData.company.company_type_id == 3){
  defaultPath = "buys"
}//Supplier
else if (userData.company.company_type_id == 4){
  defaultPath = "sales"
}
else{
  defaultPath = "orders"
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: defaultPath } },
    ...apps,
    ...dashboard,
    ...pages,
    ...claims,
    ...orders,
    ...account,
    ...users,
    ...companies,
    ...companyRelationships,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized 
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
