export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/Users.vue'),
  },

  {
    path: '/users/user-add',
    name: 'user-add',
    component: () => import('@/views/users/user-add/UserAdd.vue'),
  },

  {
    path: '/users/user-edit/:id/',
    name: 'user-edit',
    component: () => import('@/views/users/user-edit/UserEdit.vue'),
  },

]
