export default [
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/orders/Orders.vue'),  
  },

  {
    path: '/orders-completed',
    name: 'orders-completed',
    component: () => import('@/views/orders/CompletedOrders.vue'),  
  },

  {
    path: '/buys',
    name: 'buys',
    component: () => import('@/views/orders/Buys.vue'),
  },

  {
    path: '/sales',
    name: 'sales',
    component: () => import('@/views/orders/Sales.vue'),
  },

  {
    path: '/orders/order-add',
    name: 'order-add',
    component: () => import('@/views/orders/order-add/OrderAdd.vue'),
  },

  {
    path: '/orders/order-edit/:id/',
    name: 'order-edit',
    component: () => import('@/views/orders/order-edit/OrderEdit.vue'),
  },

  {
    path: '/orders/preview/:id/',
    name: 'order-preview',
    component: () => import('@/views/orders/order-preview/OrderPreview.vue'),
  },

  {
    path: '/orders/preview/:id/:purchaseId/:bookingId?',
    name: 'single-order-preview',
    component: () => import('@/views/orders/order-preview/SingleOrderPreview.vue'),
  },

  {
    path: '/orders/sale/preview/:id/:purchaseId/:bookingId?',
    name: 'sell-order-preview',
    component: () => import('@/views/orders/sell-order-preview/SellOrderPreview.vue'),
  },

  {
    path: '/orders/buy/preview/:id/:purchaseId/:bookingId?',
    name: 'buy-order-preview',
    component: () => import('@/views/orders/buy-order-preview/BuyOrderPreview.vue'),
  },

]
