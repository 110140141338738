export default [
    {
      path: '/claims',
      name: 'claims',
      component: () => import('@/views/claims/Claims.vue'),
    },
    
    {
      path: '/claims/:id',
      name: 'claim-preview',
      component: () => import('@/views/claims/ClaimDetails.vue'),
    },
  
  ]
  