export default [
  {
    path: '/company-relationships',
    name: 'company-relationships',
    component: () => import('@/views/companies/CompanyRelationships.vue'),
  },

  {
    path: '/companies/company-relationship-add',
    name: 'company-relationship-add',
    component: () => import('@/views/companies/company-relationship-add/CompanyRelationshipAdd.vue'),
  },

  {
    path: '/companies/company-relationship-edit/:id/',
    name: 'company-relationship-edit',
    component: () => import('@/views/companies/company-relationship-edit/CompanyRelationshipEdit.vue'),
  },
]
