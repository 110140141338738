import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      orderNumber: "23421L",
      purchaseOrder: "1219081",
      sellOrder: "614989",
      supplier: 'Pro Trade Steel',
      buyer: 'American Iron & Metal',
      material: 'Scrapp',
      quantity: '250',
      status: 'Created',
    },
    
    {
      id: 2,
      avatar: require('@/assets/images/avatars/10-small.png'),
      orderNumber: "23421L",
      purchaseOrder: "1219056",
      sellOrder: "614943",
      supplier: 'Pro Trade Steel',
      buyer: 'American Iron & Metal',
      material: 'Scrapp',
      quantity: '320',
      status: 'SoCreated',
    },

    {
      id: 3,
      avatar: require('@/assets/images/avatars/10-small.png'),
      orderNumber: "23425L",
      purchaseOrder: "1219081",
      sellOrder: "614989",
      supplier: 'Evraz',
      buyer: 'American Iron & Metal',
      material: 'Steel',
      quantity: '200',
      status: 'PoCreated',
    },

    {
      id: 4,
      avatar: require('@/assets/images/avatars/10-small.png'),
      orderNumber: "23421L",
      purchaseOrder: "1219081",
      sellOrder: "614989",
      supplier: 'Pro Trade Steel',
      buyer: 'Ineos',
      material: 'Scrapp',
      quantity: '250',
      status: 'Completed',
    },

    {
      id: 5,
      avatar: require('@/assets/images/avatars/10-small.png'),
      orderNumber: "23421L",
      purchaseOrder: "1219081",
      sellOrder: "614989",
      supplier: 'Pro Trade Steel',
      buyer: 'American Iron & Metal',
      material: 'Scrapp',
      quantity: '250',
      status: 'Completed',
    },

    {
      id: 6,
      avatar: require('@/assets/images/avatars/10-small.png'),
      orderNumber: "23421L",
      purchaseOrder: "1219081",
      sellOrder: "614989",
      supplier: 'Pro Trade Steel',
      buyer: 'American Iron & Metal',
      material: 'Scrapp',
      quantity: '250',
      status: 'Completed',
    },

    {
      id: 7,
      avatar: require('@/assets/images/avatars/10-small.png'),
      orderNumber: "23421L",
      purchaseOrder: "1219081",
      sellOrder: "614989",
      supplier: 'Pro Trade Steel',
      buyer: 'American Iron & Metal',
      material: 'Scrapp',
      quantity: '250',
      status: 'WaitingSupplier',
    },

    {
      id: 8,
      avatar: require('@/assets/images/avatars/10-small.png'),
      orderNumber: "23421L",
      purchaseOrder: "1219081",
      sellOrder: "614989",
      supplier: 'Pro Trade Steel',
      buyer: 'American Iron & Metal',
      material: 'Scrapp',
      quantity: '250',
      status: 'WaitingBuyer',
    },

    {
      id: 9,
      avatar: require('@/assets/images/avatars/10-small.png'),
      orderNumber: "23421L",
      purchaseOrder: "1219081",
      sellOrder: "614989",
      supplier: 'Pro Trade Steel',
      buyer: 'American Iron & Metal',
      material: 'Scrapp',
      quantity: '250',
      status: 'WaitingBuyer',
    },

    {
      id: 10,
      avatar: require('@/assets/images/avatars/10-small.png'),
      orderNumber: "23421L",
      purchaseOrder: "1219081",
      sellOrder: "614989",
      supplier: 'Pro Trade Steel',
      buyer: 'American Iron & Metal',
      material: 'Scrapp',
      quantity: '250',
      status: 'WaitingSupplier',
    },
  ],

  /* eslint-disable global-require */
}

mock.onGet('/orders-dummy/basic').reply(() => [200, data.tableBasic])
